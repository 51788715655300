/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import type { Role } from './roles';

export type HiylloID = `${string}`;

export interface LoginTokenPackageType {hashedToken: string, issued: Date, expires: Date, meta: any}

export enum AuthenticationMethodEnum {
  EmailOTP = 'email-otp',
  Password = 'password'
}

export type VerifiedDetailsType = {
  firstName: string;
  lastName: string;
  dob: { month: number; day: number; year: number; };
  address: string;
}

export interface PersonalDetailsType {
  email: string;
  phoneNumberE164: string | null;
}

export enum HiylloProductEnum {
  work = "work",
  space = "space",
  support = "support"
}

export interface HeldSeatType {
  product: HiylloProductEnum;
  /** Usually this will be the federation slug, but sometimes a given federation may want additional workspaces or support centers */
  slug: string;
}

export interface FederationDetails {
  slug: string;
  username: string;
}

export type ExtendedUserType = {
  /**
   * The user's email, required
   */
  email?: {
    address: string;
    verified: boolean;
  } | null,

  phone?: {
    e164: string;
    verified: boolean;
  } | null,

  /**
   * The user's name
   */
  name: string | null
  
  /**
   * The HiylloID represents the user's username. It will be in the format:
   *  Standard Hiyllo Account: Alphanumeric String (username) or null (bob)
   *  Employee Hiyllo Account: <employee username>@hiyllo.io (bob@hiyllo.io)
   *  Federated Hiyllo Account: <tenant>/<username> (acme/bob)
   */
  hiylloID: HiylloID;

  federationDetails: FederationDetails | null;


  /**
   * Whether the user has MFA enabled
   */
  hasMFA: boolean;

  primaryAuthenticationMethod: AuthenticationMethodEnum;

  roles: Role[]

  /**
   * Unique UUID
   */
  uuid: string;

  ips?: string[];

  verifiedDetails?: VerifiedDetailsType;
  personalDetails?: PersonalDetailsType;

  seatsHeld?: HeldSeatType[];

  deletion?: {
    d: true;
    at: Date;
    by: string;
  }
};
