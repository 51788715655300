import React from 'react';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Stardate,
  StardateLogKind,
  StardateSourceEnum
} from '@hiyllo/stardate';
import { Typography } from '@hiyllo/ux/typography';

interface StateType {
  hasError: boolean
  errorMessage: null | string
}

interface PropsType {
  children: React.ReactNode
  code?: string
  overrideFallback?: React.ReactNode
}

export class ErrorBoundary extends React.Component<PropsType, StateType> {
  constructor (props: PropsType) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: null
    };
  }

  static getDerivedStateFromError (er: Error): StateType {
    return {
      hasError: true,
      errorMessage: er.message
    };
  }

  componentDidCatch (er: Error, errorInfo: React.ErrorInfo): void {
    console.error(er, errorInfo);
    const stardate = new Stardate(
      StardateSourceEnum.frontendWeb,
      'omni',
      'error-boundary',
      null
    );

    if (window.location.hostname !== 'localhost') {
      stardate.log({
        kind: StardateLogKind.error,
        message: 'React Error boundary caught',
        data: { errorInfo },
        error: er
      });
    }
    this.setState({ errorMessage: er.message });
  }

  render (): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            // alignItems: 'center',
            paddingLeft: 50,
            background: 'black',
            color: 'white',
            height: '100%',
            width: 'calc(100% - 50px)'
          }}
        >
          <div style={{ flexGrow: 1 }}/>
          <div>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ fontSize: 40 }}
            />
          </div>
          <div style={{ height: 10 }}/>
          <Typography.Header>Hiyllo Cloud has crashed</Typography.Header>
          <Typography.SubHeader>Please try reloading the page, or email support@hiyllo.com</Typography.SubHeader>
          <div style={{ height: 20 }}/>
          <div>{this.state.errorMessage}</div>
          <div style={{ flexGrow: 1 }}/>
          <div>
            <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{
              height: 25,
              width: 'auto'
            }}/>
          </div>
          <div style={{ height: 50 }}/>
        </div>
      );
    }
    return this.props.children;
  }
}
